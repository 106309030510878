import { axiosInstance } from ".";

export const slideService = (state) => {
  return {
    async list(type) {
      const response = await axiosInstance.get(`/public/${type}`);
      return response?.data || [];
    },
  };
};
