import { useEffect, useState } from "react";
import { Container } from "./components/Container";
import { FadeIn, FadeInStagger } from "./components/FadeIn";
import { SwiperSlide, Swiper } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import services from "./services";
import { config } from "./config";
import { SocialMedia } from "./components/SocialMedia";
import { Logo } from "./components/Logo";
import { isArray } from "lodash";

function Header() {
  return (
    <div className="flex flex-col justify-center items-center w-[calc(100vw-400px)] z-100">
      {/* Tablet ve daha büyük ekran boyutları için */}
      <div className="hidden flex-row items-center justify-center sm:hidden md:hidden lg:hidden xl:flex">
        <SocialMedia
          className="mr-12"
          isVisibleTelegram={true}
          isVisibleInstagram={true}
          isVisibleYoutube={false}
          isVisibleSkype={false}
          isVisibleTwitch={false}
        />
        <div className="relative">
          <Logo />
          <SocialMedia
            isVisibleTelegram={false}
            isVisibleInstagram={false}
            isVisibleYoutube={false}
            isVisibleSkype={false}
            isVisibleTwitch={true}
          />
        </div>
        <SocialMedia
          className="ml-10"
          isVisibleTelegram={false}
          isVisibleInstagram={false}
          isVisibleYoutube={true}
          isVisibleSkype={true}
          isVisibleTwitch={false}
        />
      </div>

      {/* Mobil ekran boyutları için */}
      <div className="flex flex-col items-center justify-center sm:flex-col md:flex-col lg:flex-col xl:hidden">
        <Logo />
        <SocialMedia
          className="mt-6"
          isVisibleTelegram={true}
          isVisibleInstagram={true}
          isVisibleYoutube={true}
          isVisibleSkype={true}
          isVisibleTwitch={true}
        />
      </div>
    </div>
  );
}

function Slider({ slides }) {
  return (
    // w-[calc(100vw-10px)] sm:w-[calc(100vw-400px)] md:w-[calc(100vw-400px)] lg:w-[calc(100vw-400px)] xl:w-[calc(100vw-400px)]
    <Container className="mb-12 mt-12 w-[80%] sm:w-[60%] md:w-[60%] lg:w-[60%] xl:w-[60%]">
      <FadeIn className="h-[250px] w-full md:h-[350px] lg:h-[450px] xl:h-[550px]">
        <Swiper
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          className="h-full w-full"
          slidesPerView={1}
          modules={[Navigation, A11y, Autoplay]}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          {slides.map((slide, index) => (
            <SwiperSlide className="" key={index}>
              <a passHref href={slide?.href} target="_blank">
                <img
                  src={config.CDN_URL + slide?.image}
                  className="object-fill rounded-3xl"
                  style={{
                    objectFit: "fill",
                    width: "100%",
                    height: "100%",
                  }}
                  alt=""
                />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </FadeIn>
    </Container>
  );
}

function Sponsors({ sponsors, banners }) {
  return (
    <div className="flex flex-row justify-center items-start gap-x-6 mb-12 w-[calc(100vw)] sm:w-[calc(100vw-400px)] md:w-[calc(100vw-400px)] lg:w-[calc(100vw-400px)] xl:w-[calc(100vw-400px)]">
      <FadeIn
        key={banners[0]?.href}
        className="flex sm:hidden md:hidden lg:hidden xl:hidden  w-[calc(20vw)] sm:w-[calc(10vw)] md:w-[calc(10vw)] lg:w-[calc(10vw)] xl:w-[calc(10vw)]"
      >
        <a
          passHref
          target="_blank"
          href={banners[0]?.href}
          className="w-full h-full"
        >
          <img
            src={config.CDN_URL + banners[0]?.image}
            className="object-fill rounded-xl h-[500px] w-[100px] "
          />
        </a>
      </FadeIn>
      <FadeInStagger className="grid grid-cols-2 gap-0 sm:gap-2 md:gap-2 lg:gap-2 xl:gap-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 p-5">
        {sponsors.map((sponsor, index) => (
          <FadeIn key={`${sponsor?.href}${sponsor?.order}`} className="flex">
            <div className="relative max-w-[100px] sm:max-w-[300px] md:max-w-[300px] xl:max-w-[300px] lg:max-w-[300px] flex w-full space-y-3 flex-col p-3 ring-1 ring-yellow-700/5 transition sm:p-3 mx-auto">
              <a passHref target="_blank" href={sponsor?.href}>
                <img
                  src={`${config.CDN_URL}${sponsor?.image}`}
                  alt={"test"}
                  className="object-cover hover:bg-yellow-500 rounded-md sm:rounded-3xl md:rounded-3xl lg:rounded-3xl xl:rounded-3xl "
                  style={{
                    objectFit: "cover",
                    maxHeight: "100%",
                    maxWidth: "100%",
                  }}
                />
              </a>
            </div>
          </FadeIn>
        ))}
      </FadeInStagger>
      <FadeIn
        key={banners[1]?.href}
        className="flex sm:hidden md:hidden lg:hidden xl:hidden  w-[calc(20vw)] sm:w-[calc(10vw)] md:w-[calc(10vw)] lg:w-[calc(10vw)] xl:w-[calc(10vw)]"
      >
        <a
          passHref
          target="_blank"
          href={banners[1]?.href}
          className="w-full h-full"
        >
          <img
            src={config.CDN_URL + banners[1]?.image}
            className="object-fill rounded-xl h-[500px] w-[100px]"
          />
        </a>
      </FadeIn>
    </div>
  );
}

const HomePage = () => {
  const [sponsors, setSponsor] = useState([]);
  const [slides, setSlides] = useState([]);
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const sponsorResponse = await services.slides("SPONSOR_CARD");
        setSponsor(sponsorResponse.data);

        const slidesResponse = await services.slides("MAIN_SLIDER");
        setSlides(slidesResponse.data);

        const bannersResponse = await services.slides("COLUMN_CARD");
        setBanners(bannersResponse.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <div
        className="flex flex-col items-center justify-center"
        style={{ zIndex: 100 }}
      >
        <Header />
        {isArray(slides) && slides.length > 0 && (
          <Slider slides={slides ?? []} />
        )}
        {(sponsors.length > 0 || banners.length > 0) && (
          <Sponsors sponsors={sponsors} banners={banners} />
        )}
      </div>
      {isArray(banners) && banners.length > 0 && (
        <>
          <FadeIn
            key={banners[0]?.href}
            className="hidden sm:flex md:flex lg:flex xl:flex fixed right-5 bottom-5 w-[20vw] sm:w-[13vw] md:w-[14vw] lg:w-[15vw] xl:w-[200px] h-[calc(100vh-500px)] sm:h-[calc(100vh-200px)] md:h-[calc(100vh-200px)] lg:h-[calc(100vh-200px)] xl:h-[calc(100vh-200px)]"
          >
            <a
              passHref
              target="_blank"
              href={banners[0]?.href}
              className="w-full h-full"
            >
              <img
                src={config.CDN_URL + banners[0]?.image}
                className="object-fill rounded-3xl w-full h-full"
              />
            </a>
          </FadeIn>
          <FadeIn
            key={banners[1]?.href}
            className="hidden sm:flex md:flex lg:flex xl:flex fixed bottom-5 w-[20vw] sm:w-[13vw] md:w-[14vw] lg:w-[15vw] xl:w-[200px] h-[calc(100vh-500px)] sm:h-[calc(100vh-200px)] md:h-[calc(100vh-200px)] lg:h-[calc(100vh-200px)] xl:h-[calc(100vh-200px)]"
          >
            <a
              passHref
              target="_blank"
              href={banners[1]?.href}
              className="w-full h-full"
            >
              <img
                src={config.CDN_URL + banners[1]?.image}
                className="object-fill rounded-3xl w-full h-full"
              />
            </a>
          </FadeIn>
        </>
      )}
    </div>
  );
};

export default HomePage;
