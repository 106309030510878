import React from "react"; // Eklediğimiz satır
import SkypeIcon from "../images/skype.svg";
import InstagramIcon from "../images/instagram.svg";
import YoutubeIcon from "../images/youtube.svg";
import TelegramIcon from "../images/telegram.svg";
import "../images/neonIcons/neonIcons.css";
import { socialMediaProfiles } from "../static/socialMediaProfiles";

export function SocialMedia({
  className,
  invert = false,
  isVisibleTelegram = true,
  isVisibleInstagram = true,
  isVisibleYoutube = true,
  isVisibleSkype = true,
  isVisibleTwitch = true,
}) {
  return (
    <ul
      role="list"
      className={`flex flex-row gap-x-7 sm:gap-x-10 md:gap-x-10 lg:gap-x-10 xl:gap-x-10 justify-center items-center ${
        invert ? "text-white" : "text-yellow-700"
      } ${className}`}
    >
      {socialMediaProfiles.map((socialMediaProfile) => {
        const isVisible =
          (socialMediaProfile.title === "Telegram" && isVisibleTelegram) ||
          (socialMediaProfile.title === "Instagram" && isVisibleInstagram) ||
          (socialMediaProfile.title === "Twitch" && isVisibleTwitch) ||
          (socialMediaProfile.title === "Youtube" && isVisibleYoutube) ||
          (socialMediaProfile.title === "Skype" && isVisibleSkype);

        return isVisible ? (
          <li className="btn" key={socialMediaProfile.title}>
            <a
              target="_blank"
              href={socialMediaProfile.href}
              passHref
              className="neon-icon-link"
            >
              <div aria-label={socialMediaProfile.title} className="neon-icon">
                <div
                  dangerouslySetInnerHTML={{ __html: socialMediaProfile.icon }}
                />
              </div>
            </a>
          </li>
        ) : null;
      })}
    </ul>
  );
}
