import axios from "axios";
import { slideService } from "./slideService";
import { config } from "../config";

export const axiosInstance = axios.create({
  baseURL: config.API_URL,
});


export default {
  state: {
    config: {},
    baseUrl: "/public",
  },
  async slides(type) {
    return await slideService(this.state).list(type);
  },
};
