export function Logo({
  className,
  invert = false,
  filled = false,
  fillOnHover = false,
  ...props
}) {
  return (
    <div className="relative items-center justify-center">
      <img
        priority={true}
        objectFit="contain"
        className="w-[200px] sm:w-[400px] max-h-[350px] max-w-[560px]"
        src={require("../images/slotcousins-logo.png")}
      />
    </div>
  );
}
