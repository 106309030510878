export const socialMediaProfiles = [
  {
    title: "Telegram",
    href: "https://t.me/+XnNdwMHuLxY4YjI0",
    icon: `<svg class="neon-svg" version="1.0" xmlns="http://www.w3.org/2000/svg"
  width="33.000000pt" height="33.000000pt" viewBox="0 0 300.000000 300.000000"
  preserveAspectRatio="xMidYMid meet">

  <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
    stroke="currentColor">
    <path fill="#b3a500"
      d="M1350 2991 c-175 -19 -370 -75 -520 -151 -288 -145 -550 -413 -683
-701 -254 -546 -164 -1184 230 -1631 420 -477 1093 -636 1684 -399 455 183
802 598 904 1081 57 272 31 590 -70 852 -78 202 -178 353 -344 519 -218 217
-469 351 -764 408 -118 23 -327 33 -437 22z m440 -170 c850 -197 1301 -1119
930 -1899 -230 -482 -695 -776 -1225 -774 -643 3 -1181 444 -1321 1083 -24
110 -29 342 -10 464 77 504 416 913 896 1083 75 26 181 51 275 66 76 11 372
-3 455 -23z" />
    <path fill="#b3a500"
      d="M2055 2191 c-27 -10 -367 -143 -755 -295 -617 -242 -709 -281 -737
-311 -37 -39 -42 -78 -18 -125 20 -38 42 -49 237 -116 90 -31 167 -60 171 -64
5 -4 37 -99 71 -211 85 -275 93 -289 170 -289 31 0 49 12 141 96 63 58 111 94
119 91 8 -3 72 -50 142 -105 193 -149 198 -152 260 -152 45 0 59 5 87 30 19
16 39 42 45 57 6 16 68 319 138 675 116 587 126 649 114 678 -25 59 -93 75
-185 41" />
  </g>
</svg>
 `,
  },
  {
    title: "Instagram",
    href: "https://www.instagram.com/slotcousins/",
    icon: `
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="33.000000pt" height="33.000000pt"  viewBox="0 0 125.000000 131.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,131.000000) scale(0.100000,-0.100000)"
 
        fillRule="evenodd"
        clipRule="evenodd" stroke="none">
<path fill= "#b3a500" d="M333 1239 c-82 -10 -149 -41 -200 -91 -83 -82 -103 -176 -103 -493 0
-323 20 -415 109 -498 26 -24 74 -55 107 -68 58 -24 64 -24 379 -24 313 0 321
1 380 24 81 32 155 106 185 186 21 55 23 81 28 310 7 371 -11 471 -100 562
-51 52 -116 81 -210 93 -87 11 -487 11 -575 -1z m608 -110 c71 -15 133 -73
154 -142 21 -73 22 -591 0 -663 -19 -64 -75 -120 -140 -140 -71 -21 -590 -20
-662 1 -69 21 -127 83 -142 154 -15 69 -15 563 0 632 16 72 75 136 144 154 64
17 570 20 646 4z"/>
<path fill= "#b3a500" d="M891 1014 c-27 -35 -26 -59 4 -89 27 -28 45 -31 78 -14 29 16 37 28
37 62 0 63 -80 90 -119 41z"/>
<path fill= "#b3a500" d="M555 951 c-84 -22 -163 -88 -206 -171 -33 -65 -33 -185 0 -254 31
-63 84 -116 147 -147 70 -34 188 -34 258 0 63 31 116 84 147 147 33 69 34 189
1 256 -30 61 -90 120 -150 148 -51 24 -147 34 -197 21z m161 -121 c62 -31 97
-87 102 -166 4 -55 1 -69 -22 -108 -78 -133 -272 -131 -344 4 -33 63 -28 150
12 208 53 78 163 104 252 62z"/>
</g>
</svg>
`,
  },
  {
    title: "Twitch",
    href: "https://www.twitch.tv/slotcousins",
    icon: `
    <svg xmlns="http://www.w3.org/2000/svg" width="50.000000pt" height="50.000000pt">
    <path
        d="M5.7 0L1.4 10.985V55.88h15.284V64h8.597l8.12-8.12h12.418l16.716-16.716V0H5.7zm51.104 36.3L47.25 45.85H31.967l-8.12 8.12v-8.12H10.952V5.73h45.85V36.3zM47.25 16.716v16.716h-5.73V16.716h5.73zm-15.284 0v16.716h-5.73V16.716h5.73z"
        fill="#b3a500" fill-rule="evenodd" />
</svg>`,
  },
  {
    title: "Skype",
    href: "skype: live:.cid.30c6c0da769ac38f",
    icon: `
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
  width="33.000000pt" height="33.000000pt"  viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
 stroke="none">
<path fill= "#b3a500" d="M2340 4514 c-14 -3 -68 -12 -120 -21 -52 -8 -169 -38 -260 -66 -209
-63 -299 -70 -435 -37 -133 33 -321 38 -445 12 -181 -37 -328 -114 -460 -239
-135 -128 -215 -254 -264 -413 -87 -286 -45 -567 123 -829 94 -147 106 -192
120 -447 16 -286 75 -519 192 -763 194 -404 506 -717 914 -917 151 -73 300
-124 475 -162 116 -24 143 -26 370 -27 286 0 349 9 610 87 213 64 296 71 437
37 137 -33 378 -34 498 -1 215 59 407 194 537 377 64 90 100 161 131 261 89
281 46 571 -122 833 -94 147 -107 194 -120 442 -16 295 -75 527 -195 774 -102
207 -200 347 -361 514 -272 281 -620 472 -1020 557 -105 23 -148 27 -350 29
-126 2 -241 1 -255 -1z m470 -555 c161 -26 385 -123 541 -236 103 -73 268
-243 300 -308 33 -69 34 -190 1 -252 -34 -65 -78 -110 -135 -137 -79 -38 -134
-42 -216 -17 -61 19 -78 30 -151 100 -156 152 -310 236 -487 267 -185 33 -400
-13 -487 -104 -61 -65 -42 -166 41 -222 66 -43 128 -64 403 -135 383 -99 496
-141 656 -240 93 -58 209 -181 256 -270 166 -319 109 -716 -134 -940 -184
-170 -440 -245 -838 -245 -275 0 -496 66 -730 218 -169 109 -318 250 -361 341
-67 143 -11 307 132 386 47 26 64 30 134 30 102 0 136 -18 257 -130 103 -97
199 -160 312 -205 106 -43 186 -54 346 -47 192 7 299 45 353 124 37 56 43 105
17 158 -47 97 -149 143 -558 251 -279 74 -337 92 -448 144 -325 150 -477 387
-461 716 20 409 299 675 787 749 105 16 383 18 470 4z"/>
</g>
</svg>
`,
  },
  {
    title: "Youtube",
    href: "https://www.youtube.com/channel/UCkPs_Hl2Mt1IQPRAZ9IGZLQ",
    icon: `
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
   width="33.000000pt" height="33.000000pt" viewBox="0 0 900.000000 900.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)"
 stroke="none">
<path fill= "#b3a500" d="M3780 7250 c-767 -28 -1964 -122 -2260 -176 -174 -32 -342 -118 -470
-240 -183 -175 -258 -322 -320 -623 -36 -179 -64 -391 -87 -661 -15 -175 -18
-328 -18 -1040 0 -845 4 -982 41 -1310 20 -175 59 -410 89 -532 87 -355 354
-634 695 -727 173 -47 1423 -156 2025 -176 99 -4 194 -8 210 -11 59 -9 1305
-12 1530 -4 635 24 1215 64 1880 131 165 16 338 37 384 45 198 36 364 127 517
286 185 190 260 394 324 878 47 357 52 494 55 1315 2 429 -1 859 -6 955 -24
472 -92 924 -168 1113 -112 280 -350 499 -632 580 -179 53 -1388 160 -2044
182 -99 4 -193 8 -210 11 -58 9 -1313 12 -1535 4z m170 -1563 c30 -13 188 -95
350 -183 162 -88 578 -313 925 -499 676 -365 716 -391 730 -469 12 -63 -2
-104 -54 -155 -37 -36 -157 -105 -556 -320 -281 -151 -697 -376 -925 -500
-228 -124 -442 -236 -474 -249 -110 -45 -210 -12 -257 85 l-24 48 -3 1025 c-2
697 1 1041 8 1075 30 140 144 198 280 142z"/>
</g>
</svg>
`,
  },
];
